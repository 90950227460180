import Splash from 'components/Segments/Splash';
import SearchBookmarks from 'components/Search/SearchBookmarks';
import EmergeSplash from 'components/SplashPages/Emerge';
import WomenSplash from 'components/SplashPages/Women';
import StigmastodonSplash from 'components/SplashPages/Stigmastodon';
import GreencheckSplash from 'components/SplashPages/GreenCheck';
import PPPartySplash from 'components/SplashPages/PPParty';

// app-wide settings:
const defaultAppConfig = {
  HomeRouteComponent: Splash,
  groupSelector: true,
  showBetaNotes: true
};

// app-switcher settings:
const appSwitcherConfig = {
  appSwitcher: true
};

// app-static (no switcher) settings:
const appStaticConfig = {
  appSwitcher: false
};

const domainConfig = {
  'bestofnow.net': {
    ...defaultAppConfig,
    ...appStaticConfig,
    netName: 'best_of',
    HomeRouteComponent: SearchBookmarks,
    chatTenantToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnROYW1lIjoiYmVzdG9mbm93Lm5ldCIsImRvbWFpbiI6ImJlc3RvZm5vdy5uZXQiLCJlbnYiOiJiZXN0b2Zub3cubmV0IiwidGVuYW50SUQiOiJXSGJFUVFBbnkzOFl4cDJxUCIsImV4cCI6MzM0NTg5Nzg0NTEsImlhdCI6MTY0OTYwODg1MH0.DIekPBaO82nhMxtXGYqOOUESglsPK1E3QqrzL53LNZ4'
  },
  'perspectiva.bestofnow.net': {
    ...defaultAppConfig,
    ...appStaticConfig,
    netName: 'perspectiva',
    // HomeRouteComponent: SearchBookmarks,
    chatTenantToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnROYW1lIjoiYmVzdG9mbm93Lm5ldCIsImRvbWFpbiI6ImJlc3RvZm5vdy5uZXQiLCJlbnYiOiJiZXN0b2Zub3cubmV0IiwidGVuYW50SUQiOiJXSGJFUVFBbnkzOFl4cDJxUCIsImV4cCI6MzM0NTg5Nzg0NTEsImlhdCI6MTY0OTYwODg1MH0.DIekPBaO82nhMxtXGYqOOUESglsPK1E3QqrzL53LNZ4'
  },
  'stayforward.net': {
    ...defaultAppConfig,
    ...appStaticConfig,
    netName: 'sharebnb'
  },
  'blueswarm.us': {
    ...defaultAppConfig,
    ...appStaticConfig,
    netName: 'blueswarm',
    chatTenantToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnROYW1lIjoiYmx1ZXN3YXJtLnVzIiwiZG9tYWluIjoiYmx1ZXN3YXJtLnVzIiwiZW52IjoiYmx1ZXN3YXJtLnVzIiwidGVuYW50SUQiOiJXSGJFUVFBbnkzOFl4cDJxUCIsImV4cCI6MzM0NTg5Nzg0NzIsImlhdCI6MTY0OTYwODg3Mn0.kfo_9eSrj50GkfOSHELH0AsjZITA-DXSXm47iFZhh0A'
  },
  'emerge.noo.network': {
    ...defaultAppConfig,
    ...appStaticConfig,
    netName: 'emerge',
    HomeRouteComponent: EmergeSplash,
    groupSelector: false,
    showBetaNotes: false,
    chatTenantToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnROYW1lIjoiZW1lcmdlLm5vby5uZXR3b3JrIiwiZG9tYWluIjoiZW1lcmdlLm5vby5uZXR3b3JrIiwiZW52IjoiZW1lcmdlLm5vby5uZXR3b3JrIiwidGVuYW50SUQiOiJXSGJFUVFBbnkzOFl4cDJxUCIsImV4cCI6MzM0NjQ4NDE5NjgsImlhdCI6MTY1NTQ3MjM2OH0.8u1GJJYl4heG8mpsAfTga9hhA7F_hSdLFA428jkG5H8'
  },
  'climate.noo.network': {
    ...defaultAppConfig,
    ...appSwitcherConfig,
    netName: 'climate',
    chatTenantToken: ''
  },
  'whoknows.noo.network': {
    ...defaultAppConfig,
    ...appStaticConfig,
    netName: 'whoknows',
    chatTenantToken: ''
  },
  'kernel.bestofnow.net': {
    ...defaultAppConfig,
    ...appStaticConfig,
    groupSelector: true,
    netName: 'kernel',
    chatTenantToken: ''
  },
  'twitter.noo.network': {
    ...defaultAppConfig,
    ...appSwitcherConfig,
    netName: 'ph',
    chatTenantToken: ''
  },
  'noo.network': {
    ...defaultAppConfig,
    ...appSwitcherConfig,
    netName: 'nao',
    chatTenantToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnROYW1lIjoibm9vLW5ldHdvcmtzLW1haW4uaGVyb2t1YXBwLmNvbSIsImRvbWFpbiI6Im5vby1uZXR3b3Jrcy1tYWluLmhlcm9rdWFwcC5jb20iLCJlbnYiOiJub28tbmV0d29ya3MtbWFpbi5oZXJva3VhcHAuY29tIiwidGVuYW50SUQiOiJXSGJFUVFBbnkzOFl4cDJxUCIsImV4cCI6MzM0Nzk4MDkyMTYsImlhdCI6MTYzODkwMzYxNX0.Db5DxKqe-eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnROYW1lIjoibm9vLm5ldHdvcmsiLCJkb21haW4iOiJub28ubmV0d29yayIsImVudiI6Im5vby5uZXR3b3JrIiwidGVuYW50SUQiOiJXSGJFUVFBbnkzOFl4cDJxUCIsImV4cCI6MzM0NjU5MjI0ODcsImlhdCI6MTY1NjU1Mjg4N30.S2LPuwNLIz5JZM0yu2ZWw0Hm5MKntSrR21Tot-L3BIM'
    // netName: 'demo'
  },
  'nao.is': {
    ...defaultAppConfig,
    ...appSwitcherConfig,
    netName: 'nao'
    // netName: 'demo'
  },
  'greencheck.world': {
    ...defaultAppConfig,
    ...appStaticConfig,
    netName: 'greencheck',
    _HomeRouteComponent: GreencheckSplash
    // netName: 'demo'
  },
  'stigmastodon.com': {
    ...defaultAppConfig,
    ...appStaticConfig,
    HomeRouteComponent: StigmastodonSplash,
    netName: 'ph'
  },
  'stigmastodon.noo.network': {
    ...defaultAppConfig,
    ...appStaticConfig,
    HomeRouteComponent: StigmastodonSplash,
    netName: 'ph'
  },
  'newday.noo.network': {
    ...defaultAppConfig,
    ...appStaticConfig,
    netName: 'newday'
  },
  'netcoop.noo.network': {
    ...defaultAppConfig,
    ...appSwitcherConfig,
    netName: 'netcoop',
    chatTenantToken: ''
  },
  'portable.noo.network': {
    ...defaultAppConfig,
    ...appSwitcherConfig,
    netName: 'portable',
    chatTenantToken: ''
  },
  'greencheck.noo.network': {
    ...defaultAppConfig,
    ...appStaticConfig,
    netName: 'greencheck',
    chatTenantToken: ''
  },
  'women.noo.network': {
    ...defaultAppConfig,
    ...appSwitcherConfig,
    netName: 'women',
    HomeRouteComponent: WomenSplash,
    chatTenantToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnROYW1lIjoiZW1lcmdlLm5vby5uZXR3b3JrIiwiZG9tYWluIjoiZW1lcmdlLm5vby5uZXR3b3JrIiwiZW52IjoiZW1lcmdlLm5vby5uZXR3b3JrIiwidGVuYW50SUQiOiJXSGJFUVFBbnkzOFl4cDJxUCIsImV4cCI6MzM0NjQ4NDE5NjgsImlhdCI6MTY1NTQ3MjM2OH0.8u1GJJYl4heG8mpsAfTga9hhA7F_hSdLFA428jkG5H8'
  },
  'ppparty.noo.network': {
    ...defaultAppConfig,
    ...appStaticConfig,
    netName: 'ppparty',
    HomeRouteComponent: PPPartySplash,
    chatTenantToken: ''
  },
  localip: {
    ...defaultAppConfig,
    ...appStaticConfig,
    netName: 'nao'
  },
  'standingwave.net': {
    chatTenantToken:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnROYW1lIjoic3RhbmRpbmd3YXZlLm5ldCIsImRvbWFpbiI6InN0YW5kaW5nd2F2ZS5uZXQiLCJlbnYiOiJzdGFuZGluZ3dhdmUubmV0IiwidGVuYW50SUQiOiJXSGJFUVFBbnkzOFl4cDJxUCIsImV4cCI6MzM0Nzk4OTYzNDIsImlhdCI6MTYzODk5MDc0MX0.HHsP-eWp3Ofwvr7206cxgX9n666JhpOIDfTNf9zId-I'
  },
  // aliases (can be localhost aliases or other domains for convenience)
  // don't change these unless you also change the package.json launch points
  // so we don't get confused on what is happening for local testing :-)
  'localhost:3005': 'bestofnow.net',
  'localhost:3006': 'noo.network',
  'localhost:3007': 'blueswarm.us',
  'localhost:3008': 'emerge.noo.network',
  'localhost:3009': 'nao.is',
  'localhost:3010': 'perspectiva.bestofnow.net',
  'localhost:3011': 'kernel.bestofnow.net',
  'localhost:3012': 'twitter.noo.network',
  'localhost:3013': 'stigmastodon.com',
  'localhost:3014': 'stigmastodon.noo.network',
  'localhost:3015': 'greencheck.world',
  'localhost:3016': 'whoknows.noo.network',

  // NB: ADD TO enabled in shared/config/index.js
  default: 'noo.network' // 'ppparty.noo.network' //  'portable.noo.network' //  'women.noo.network' //  'greencheck.noo.network' // 'greencheck.world' //  'newday.noo.network' //   '  'whoknows.noo.network' //    'climate.noo.network' //   'stayforward.net' //
};

const domainAppConfig = host => {
  const shortHost = host.replace('www.', '');
  const config = domainConfig[shortHost];
  if (config) {
    if (typeof config === 'string') {
      const aliasConfig = domainConfig[config];
      if (aliasConfig) {
        return aliasConfig;
      }
      // string is supposed to be an alias, not found so return null
      return null;
    }
    return config;
  }
  // catch-all for regular "npm start" run:
  if (host.indexOf('localhost') > -1) {
    console.error('no domain matched, but we are in localhost so sending back default config....');
    return domainConfig[domainConfig.default];
  }
  return null;
};

export default domainAppConfig;
